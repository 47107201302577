import { ApplicationConfig, provideZoneChangeDetection, isDevMode } from '@angular/core';
import { provideRouter, withPreloading } from '@angular/router';
import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { httpInterceptor } from './core/interceptor/http.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CustomPreloadingStrategyService } from './core/services/custom-preloading-strategy.service';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [provideAnimationsAsync(),
  provideZoneChangeDetection({ eventCoalescing: true }),
  provideAnimations(),
  provideHttpClient(
    withInterceptors([httpInterceptor])
  ),
  provideRouter(routes, withPreloading(CustomPreloadingStrategyService)),
  provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};
