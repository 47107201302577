import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 0:
          console.log('No internet connection', 'Connection Error');
          break;
        case 400:
          console.log('Bad Request: ' + error.error?.message || 'An error occurred', 'Error');
          break;
        case 401:
          console.log('Unauthorized: You are not authorized to access this resource', 'Error');
          break;
        case 404:
          console.log('Not Found: The requested resource could not be found', 'Error');
          break;
        case 500:
          console.log('Internal Server Error: Something went wrong on the server', 'Error');
          break;
        default:
          console.log('An unexpected error occurred', 'Error');
      }
      return throwError(() => error);
    })
  );
};
