import { Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { CpRedirectComponent } from './screens/cp-redirect/cp-redirect.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'selectmasjid',
        pathMatch: 'full'
    },
    {
        path: 'selectmasjid',
        loadComponent: () => import('./selectmasjid/selectmasjid.component').then(c => c.SelectmasjidComponent),
        title: 'Select Masjid'
    },
    {
        path: 'timingscreen/:id',
        loadComponent: () => import('./screens/timingscreen/timingscreen.component').then(c => c.TimingscreenComponent),
        data: { preload: true },
        title: 'Timing Screen'
    },
    {
        path: 'timinginfoscreen/:id',
        loadComponent: () => import('./screens/time-info-screen/time-info-screen.component').then(c => c.TimeInfoScreenComponent),
        title: 'Time Info Screen'
    },
    {
        path: 'infoscreen/:id',
        loadComponent: () => import('./screens/info-screen/info-screen.component').then(c => c.InfoScreenComponent),
        title: 'Info Screen'
    },
    {
        path: 'salahscreen/:id',
        loadComponent: () => import('./screens/multiple-salah-screen/multiple-salah-screen.component').then(c => c.MultipleSalahScreenComponent),
        title: 'Multiple Salah Screen'
    },
    {
        path: 'mobilescreen/:id',
        loadComponent: () => import('./screens/mobile-screen/mobile-screen.component').then(c => c.MobileScreenComponent),
        title: 'Mobile Screen'
    },
    {
        path: 'sharescreen/:id',
        loadComponent: () => import('./screens/share-screen/share-screen.component').then(c => c.ShareScreenComponent),
        title: 'Share Screen'
    },
    {
        path: 'widget/:id',
        loadComponent: () => import('./screens/widget/widget.component').then(c => c.WidgetComponent),
        title: 'Widget'
    },
    {
        path: 'login',
        component:CpRedirectComponent,
        title:'Redirecting to CP' // Ensure the full path is matched
    },
    { path: '**', redirectTo: environment.controlPanelUrl, pathMatch: 'full' }
];
