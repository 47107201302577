import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoaderComponent } from "../../core/components/loader/loader.component";

@Component({
  selector: 'mm-cp-redirect',
  standalone: true,
  imports: [LoaderComponent],
  templateUrl: './cp-redirect.component.html',
  styleUrl: './cp-redirect.component.scss'
})
export class CpRedirectComponent implements OnInit {
  ngOnInit(): void {
    // Redirect to the control panel URL
    window.location.href = `${environment.controlPanelUrl}/login`;
  }
}
